import React, { useRef, useState } from "react"

import useAutosizeTextArea from "./useAutosizeTextArea.jsx";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

export default function Input({ disableInput, onSend, startNewChat, guideMe, handleFileChange, fileList, fileUploaded, deleteFile, setSourceDB }) {
    const sourceCategories = [
        'Legislation and supporting material',
        'Public rulings',
        'Practical compliance guidelines',
        'Cases',
        'Decision impact statements',
        'ATO interpretative decisions',
        'Law administration practice statements',
        'Taxpayer alerts',
        "SMSF Regulators Bulletins",
        'Other ATO documents',
        'ATO law aids',
        'Edited private advice',
        'Archived document types'
    ];

    const [value, setValue] = useState("")
    const textAreaRef = useRef(null)
    const [dbSource, setDBSource] = useState([
        'Legislation and supporting material',
        'Public rulings',
        'Practical compliance guidelines',
        'Cases',
        'Decision impact statements',
        'ATO interpretative decisions',
        'Law administration practice statements',
        'Taxpayer alerts',
        "SMSF Regulators Bulletins",
        'Other ATO documents',
        'ATO law aids',
        'Edited private advice',
        'Archived document types'
    ])
    const [sourceSelection, setSourceSelection] = useState('All')

    const handleSend = e => {
        e.preventDefault();
        onSend(value)
        setValue("")
    }

    const onEnterPress = e => {
        if (e.code === "Enter" && e.shiftKey == false) {
            console.log("Enter key was pressed. Run your function.");
            e.preventDefault();
            handleSend(e);
        }
    }

    const handleChange = evt => {
        const val = evt.target?.value

        setValue(val)
    }

    const isAllSelected =
        sourceCategories.length > 0 && dbSource?.length === sourceCategories.length;

    const handleOptionChange = async (event) => {
        const value = event.target.value;
        var local_sources = value.join(", ")
        if (value[value.length - 1] === "All") {
            if (dbSource?.length === sourceCategories.length) {
                setDBSource([])
                setSourceDB([])
                setSourceSelection(
                    "no"
                );
            } else {
                setDBSource(sourceCategories)
                setSourceDB(["All"])
                setSourceSelection(
                    "All"
                );
            }

            return;
        }
        setDBSource(value);
        setSourceDB(value);

        if (value == "") {
            setSourceSelection(
                "no"
            );
        } else {
            setSourceSelection(
                local_sources
            );
        }
    };

    useAutosizeTextArea(textAreaRef.current, value)

    return (
        <>
            <div className={`abacus-input-header`}>
                <button className={`abacus-input-new-chat-btn `} onClick={() => startNewChat()}>
                    New Chat
                </button>
                <button className={`abacus-input-new-chat-btn `} onClick={() => guideMe()}>
                    Guide Me
                </button>
                {fileUploaded ? (
                    <button className={`abacus-input-new-chat-btn files`} onClick={deleteFile}>
                        Delete Uploads
                    </button>
                ) : (

                    <>
                        <label for='input-file' className="abacus-input-upload-label">
                            <p className={`abacus-input-new-chat-btn files`}>
                                Upload Files
                            </p>
                        </label>
                    </>
                )}
                <div className="source-button-container">
                    <FormControl id="abacus-souce-options" sx={{ m: 1, minWidth: 120 }}>
                        {dbSource == "" ? <InputLabel id="abacus-souce-options-input">No Sources</InputLabel>
                            :
                            <InputLabel id="abacus-souce-options-input">Sources</InputLabel>}

                        <Select
                            labelId="abacus-souce-options-label"
                            id="abacus-souce-options-select"
                            value={dbSource}
                            onChange={handleOptionChange}
                            label="Sources"
                            input={<OutlinedInput label="Sources" />}
                            multiple
                            renderValue={(selected) => selected.join(', ')}
                        >
                            <MenuItem
                                value="All"
                                id="abacus-souce-options-item"
                            >
                                <Checkbox
                                    checked={isAllSelected}
                                    indeterminate={
                                        dbSource?.length > 0 && dbSource.length < sourceCategories.length
                                    }
                                />

                                <ListItemText
                                    primary="All"
                                />
                            </MenuItem>
                            {sourceCategories.map((source) => (
                                <MenuItem id="abacus-souce-options-item" key={source} value={source}>
                                    <Checkbox id="abacus-souce-options-item-checkbox" checked={dbSource?.includes(source)} />
                                    <ListItemText id="abacus-souce-options-item-text" primary={source} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div >
            <div className="abacus-input-container">
                <form onSubmit={handleSend} className={`abacus-input-box ${disableInput}`}>
                    <div className="input-body">
                        <textarea
                            ref={textAreaRef}
                            rows={1}
                            value={value}
                            placeholder="Ask your question..."
                            onChange={handleChange}
                            onKeyDown={onEnterPress}
                            disabled={disableInput}
                        ></textarea>
                        <p className="source-list">with {sourceSelection} sources </p>
                    </div>
                    <input readonly={disableInput} id='input-file' multiple type="file" onChange={handleFileChange} />
                    <button className={`abacus-input-enter-chat-btn `} onClick={(e) => handleSend(e)}>
                        Submit
                    </button>
                </form >
                {fileUploaded ? (
                    <div className="abacus-uploaded-files">
                        {fileList.map((file) =>
                            <div className="abacus-uploaded-file">
                                <p>
                                    {file}
                                </p>
                            </div>
                        )}
                    </div>
                ) : (<></>)}
            </div>
        </>
    )
}
